import React from 'react';
import TemplatesPage from '../../components/pages/Templates/TemplatesPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import BasicRepository from '../../repositories/BasicRepository';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class TemplatesController extends LocalizedController {
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;
    private repository: BasicRepository;

    state = {
        items: [],
    };

    constructor(props) {
        super(props);
        const [router, routeMenuItemFactory, localizationService, repository, gatekeeperFactory] = props.args;

        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
        this.repository = repository;
    }

    async componentDidMount() {
        await this.fetch();
    }

    async fetch() {
        let items = await this.getTemplates();

        this.setState({
            items,
        })
    }

    async getTemplates() {
        let websiteInfo;
        let items;

        try {
            websiteInfo = await this.repository.get();
            items = await this.repository.getByPath(`websites/${websiteInfo[0].id}/templates`);
        } catch (e) {
            console.error(e);
        }
        return items;
    }

    async createTemplate(data: any) {
        let websiteInfo;
        let entity;

        try {
            websiteInfo = await this.repository.get();
            entity = { name: data.name, websiteId: websiteInfo[0].id }
            await this.repository.createByPath(entity, `websites/${websiteInfo[0].id}/templates`);
            await this.fetch();
        } catch (e) {
            console.error(e);
        }
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Website Template Management'), value: undefined },
        ]
    }

    render() {
        const Gatekeeper = this.gatekeeperFactory.create();
        const { items } = this.state;

        return this.prepare(
            <TemplatesPage
                Gatekeeper={Gatekeeper}
                items={items}
                breadcrumbLevels={this.generateBreadcrumbs()}
                createTemplate={async (data) => { this.createTemplate(data) }}
            />
        )
    }
}
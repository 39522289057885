const routesValues = [
    {
        "methods": ["get"],
        "controller": 'controller.overview',
        "key": 'Overview',
        "icon": "home",
        "path": "/",
        "title": "Home",
        "header": true,
    },
    {
        "methods": ["get"],
        // "controller": 'controller.websitesettings',
        "controller": 'controller.websiteconfiguration',
        "key": 'Website',
        "path": "/website-management",
        // "title": "Blog",
        "featureKey": "website-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.blog',
        "key": 'Blog',
        "path": "/posts",
        // "title": "Blog",
        "featureKey": "blog-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.blogcreate',
        "path": "/posts/create",
    },
    {
        "methods": ["get"],
        "controller": 'controller.blogedit',
        "path": "/posts/:id/edit",
    },
    {
        "methods": ["get"],
        "controller": 'controller.sections',
        "key": 'Sections',
        "path": "/website-management/sections",
        // "title": "Sections",
        "featureKey": "website-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.sectioncreate',
        "path": "/website-management/sections/create",
    },
    {
        "methods": ["get"],
        "controller": 'controller.sectionedit',
        "path": "/website-management/sections/:id/edit",
    },
    {
        "methods": ["get"],
        "controller": 'controller.features',
        "key": 'Features',
        "path": "/features",
        // "title": "Features",
        // "disabled": true,
        "featureKey": "feature-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.featurescreate',
        "path": "/features/create",
    },
    {
        "methods": ["get"],
        "controller": 'controller.featuresedit',
        "path": "/features/:id/edit",
    },
    {
        "methods": ["get"],
        "controller": 'controller.license',
        "key": 'Licenses',
        "path": "/licenses",
        // "title": "Licenses",
        // "disabled": true,
        "featureKey": "license-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.licensecreate',
        "path": "/licenses/create",
        "featureKey": "license-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.licenseoverview',
        "path": "/licenses/:id/edit",
    },
    {
        "methods": ["get"],
        "controller": 'controller.account',
        "key": 'Accounts',
        "path": "/accounts",
        "featureKey": "account-view",
    },
    {
        "methods": ["get"],
        "controller": 'controller.accountcreate',
        "path": "/accounts/create",
        "featureKey": "account-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.accountoverview',
        "path": "/accounts/:id",
        "featureKey": "account-view",
    },
    {
        "methods": ["get"],
        "controller": 'controller.accountedit',
        "path": "/accounts/:id/edit",
        "featureKey": "account-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.users',
        "key": 'Users',
        "path": "/users",
        // "title": "Users",
        "featureKey": "user-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.usercreate',
        "path": "/users/create",
        "featureKey": "user-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.useredit',
        "path": "/users/:id/edit",
        "featureKey": "user-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.subscriptions',
        "key": 'Subscriptions',
        "path": "/subscriptions",
        // "title": "Subscriptions",
        "featureKey": "subscription-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.subscriptioncreate',
        "path": "/subscriptions/create",
        "featureKey": "subscription-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.subscriptionoverview',
        "path": "/subscriptions/:id",
        "featureKey": "subscription-view",
    },
    {
        "methods": ["get"],
        "controller": 'controller.subscriptionedit',
        "path": "/subscriptions/:id/edit",
        "featureKey": "subscription-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.accountsubscriptionsnew',
        "path": "/accounts/:id/subscriptions/associate",
        "featureKey": "subscription-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.applications',
        "key": 'Applications',
        "path": "/applications",
        // "title": "Applications",
        "featureKey": "application-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.applicationcreate',
        "path": "/applications/create",
    },
    {
        "methods": ["get"],
        "controller": 'controller.applicationoverview',
        "path": "/applications/:id",
    },
    {
        "methods": ["get"],
        "controller": 'controller.applicationedit',
        "path": "/applications/:id/edit",
    },
    {
        "methods": ["get"],
        "controller": 'controller.archetypes',
        "key": 'Archetypes',
        "path": "/archetypes",
        // "title": "Archetypes",
        "featureKey": "archetype-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.archetypecreate',
        "path": "/archetypes/create",
    },
    {
        "methods": ["get"],
        "controller": 'controller.archetypeoverview',
        "path": "/archetypes/:id",
    },
    {
        "methods": ["get"],
        "controller": 'controller.archetypeedit',
        "path": "/archetypes/:id/edit",
    },
    {
        "methods": ["get"],
        "controller": 'controller.components',
        "key": 'Components',
        "path": "/components",
        // "title": "Components",
        "featureKey": "component-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.componentcreate',
        "path": "/components/create",
    },
    // {
    //     "methods": ["get"],
    //     "controller": 'controller.componentoverview',
    //     "path": "/components/:id",
    // },
    {
        "methods": ["get"],
        "controller": 'controller.componentedit',
        "path": "/components/:id/edit",
    },
    {
        "methods": ["get"],
        "controller": "controller.carts",
        "key": 'Carts',
        "path": "/carts",
        // "title": "Carts",
        "featureKey": "cart-management",
    },
    {
        "methods": ["get"],
        "controller": "controller.productcatalog",
        "key": 'Catalogs',
        "path": "/store-management/catalogs",
        // "title": "Blog",
        "featureKey": "catalog-management",
    },
    {
        "methods": ["get"],
        "controller": "controller.storefronts",
        "key": 'Catalogs',
        "path": "/store-management",
        // "title": "Blog",
        // "featureKey": "store-management",
    },
    {
        "methods": ["get"],
        "controller": "controller.storefrontconfigurationoverview",
        "key": 'Catalogs',
        "path": "/store-management/:id/overview",
        // "title": "Blog",
        // "featureKey": "store-management",
    },
    {
        "methods": ["get"],
        "controller": "controller.productcatalogcreate",
        "path": "/store-management/catalogs/create",
        "featureKey": "catalog-management",
    },
    {
        "methods": ["get"],
        "controller": "controller.productcatalogedit",
        "path": "/store-management/catalogs/:id/edit",
        "featureKey": "catalog-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.websitedeployment',
        "key": 'Deployments',
        "path": "/website-management/deployments",
        "featureKey": "website-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.messagesconfigurationsettings',
        "key": 'Messaging Configuration',
        "path": "/website-management/messaging-settings",
        "featureKey": "messages-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.product',
        "key": 'Products',
        "path": "/store-management/products",
        "featureKey": "product-management",
    },
    {
        "methods": ["get"],
        "controller": 'controller.invoice',
        "path": "/store-management/invoices",
    },
    {
        "methods": ["get"],
        "controller": 'controller.invoiceoverview',
        "path": "/store-management/invoices/:id",
    },
    {
        "methods": ["get"],
        "controller": 'controller.order',
        "path": "/store-management/orders",
    },
    {
        "methods": ["get"],
        "controller": 'controller.orderoverview',
        "path": "/store-management/orders/:id",
    },
    {
        "methods": ["get"],
        "controller": 'controller.productoverview',
        "path": "/store-management/products/:id",
    },
    {
        "methods": ["get"],
        "controller": 'controller.productcreate',
        "path": "/store-management/products/create",
    },
    {
        "methods": ["get"],
        "controller": 'controller.productedit',
        "path": "/store-management/products/:id/edit",
    },
    {
        "methods": ["get"],
        "controller": 'controller.productdefinitioncreate',
        "path": "/productdefinitions/create",
    },
    {
        "methods": ["get"],
        "controller": 'controller.productdefinitionedit',
        "path": "/productdefinitions/:id/edit",
    },
    {
        "methods": ["get"],
        "controller": 'controller.productdefinitionsattributes',
        "path": "/productdefinitions/:id/attributes",
    },
    {
        "methods": ["get"],
        "controller": 'controller.productdefinitionspriceshipping',
        "path": "/productdefinitions/:id/priceshipping",
    },
    {
        "methods": ["get"],
        "controller": 'controller.productdefinitionsconfirmation',
        "path": "/productdefinitions/:id/confirmation",
    },
    {
        "methods": ["get"],
        "controller": 'controller.productdefinitionsoverview',
        "path": "/productdefinitions/:id",
    },
    {
        "methods": ["get"],
        "controller": 'controller.pages',
        "path": "/website-management/pages",
    },
    {
        "methods": ["get"],
        "controller": 'controller.pagescreate',
        "path": "/website-management/pages/create",
    },
    {
        "methods": ["get"],
        "controller": 'controller.pagesoverview',
        "path": "/website-management/pages/:id",
    },
    {
        "methods": ["get"],
        "controller": 'controller.pagesedit',
        "path": "/website-management/pages/:id/edit",
    },
    {
        "methods": ["get"],
        "controller": 'controller.sectioncreate',
        "path": "/website-management/pages/:id/sections/create",
    },
    {
        "methods": ["get"],
        "controller": 'controller.applicationtemplates',
        "path": "/applicationtemplates",
        "key": 'ApplicationTemplates',
        // "title": 'Application Templates',
        "featureKey": 'applicationtemplate-management',
    },
    {
        "methods": ["get"],
        "controller": 'controller.applicationtemplatescreate',
        "path": "/applicationtemplates/create",
    },
    {
        "methods": ["get"],
        "controller": 'controller.applicationinstancescreate',
        "path": "/build/subscription",
    },
    {
        "methods": ["get"],
        "controller": 'controller.applicationtemplatesedit',
        "path": "/applicationtemplates/:id/edit",
    },
    {
        "methods": ["get"],
        "controller": 'controller.taxes',
        "path": "/store-management/taxes",
    },
    {
        "methods": ["get"],
        "controller": 'controller.taxescreate',
        "path": "/store-management/taxes/create",
    },
    {
        "methods": ["get"],
        "controller": 'controller.taxesedit',
        "path": "/store-management/taxes/:id/edit",
    },
    {
        "methods": ["get"],
        "controller": 'controller.inbox',
        "path": "/inbox",
        "title": "Inbox",
        "position": "right",
        "icon": "inbox",
        "id": "inbox",
        // "disabled": true,
        "featureKey": "messages-management",
    },
    // {
    //     "methods": ["get"],
    //     "controller": 'controller.applicationsettings',
    //     "path": "/settings/site",
    // },
    // {
    //     "methods": ["get"],
    //     "controller": 'controller.themelayoutsettings',
    //     "path": "/settings/theme",
    // },
    // {
    //     "methods": ["get"],
    //     "controller": 'controller.usersettings',
    //     "path": "/settings/users",
    // },
    // {
    //     "methods": ["get"],
    //     "controller": 'controller.groupsettings',
    //     "path": "/settings/groups",
    //     "featureKey": "group-view",
    // },
    // {
    //     "methods": ["get"],
    //     "controller": 'controller.groupsettingscreate',
    //     "path": "/settings/groups/create",
    //     "featureKey": "group-management",
    // },
    // {
    //     "methods": ["get"],
    //     "controller": 'controller.groupsettingsedit',
    //     "path": "/settings/groups/:id/edit",
    //     "featureKey": "group-management",
    // },
    // {
    //     "methods": ["get"],
    //     "controller": 'controller.accountsettings',
    //     "path": "/settings/account",
    //     "title": "Settings",
    //     "icon": "cog",
    //     "id": "settings",
    // },
    // {
    //     "methods": ["get"],
    //     "controller": 'controller.billingplansettings',
    //     "path": "/settings/billingplan",
    // },
    // {
    //     "methods": ["get"],
    //     "controller": 'controller.resourcesettings',
    //     "path": "/settings/resources",
    //     "featureKey": "resource-view",
    // },
    // {
    //     "methods": ["get"],
    //     "controller": 'controller.resourcesettingscreate',
    //     "path": "/settings/resources/create",
    //     "featureKey": "resource-management",
    // },
    // {
    //     "methods": ["get"],
    //     "controller": 'controller.resourcesettingsedit',
    //     "path": "/settings/resources/:id/edit",
    //     "featureKey": "resource-management",
    // },
    // {
    //     "methods": ["get"],
    //     "controller": 'controller.securitysettings',
    //     "path": "/settings/security",
    // },
    {
        "controller": 'controller.templatesoverview',
        "methods": ["get"],
        "path": "/website-template-management",
    },
    {
        "controller": 'controller.templatesedit',
        "methods": ["get"],
        "path": "/website-template-management/:id/edit",
    },
    // {
    //     "controller": 'controller.events',
    //     "methods": ["get"],
    //     "path": "/events",
    // },
    {
        "controller": 'controller.login',
        "methods": ["get"],
        "path": "/login",
    },
    {
        "controller": 'controller.passwordreset',
        "methods": ["get"],
        "path": "/reset",
    },
    {
        "controller": 'controller.signup',
        "methods": ["get"],
        "path": "/signup",
    },
    {
        "controller": 'controller.activation',
        "methods": ["get"],
        "path": "/activate",
    },
    {
        "controller": 'controller.not_found',
        "methods": ["get"],
        "path": "*",
    },
];

export default routesValues;

import React, { PureComponent } from "react";
import { Button, Form, Grid, Modal } from "semantic-ui-react";
import CommonUtils from "../../../../../utilities/Common/CommonUtils";
import MarkdownEditor from "../../../../controls/MarkdownEditor";
import ReactMarkdown from "react-markdown";
import copy from "clipboard-copy";
import { ToastPosition, toast } from "react-toastify";

const commonUtils = new CommonUtils();

export interface IQuickContentCreationFormProps {
    content?: any;
    isMobile?: boolean;
    edit?: boolean;
    handleChange?: ({ name, value }) => any;
    toggleModal: (toggleModal: boolean) => void;
    onSave: (data: any) => void;
    localizationService: any;
}

export interface IQuickContentCreationFormState {
    id: string;
    title: string;
    content: string;
    language: string;
}

export default class QuickContentCreationForm extends PureComponent<IQuickContentCreationFormProps, IQuickContentCreationFormState> {

    static defaultProps = {
        onSave: (data) => { console.log(data) },
        edit: false,
    };

    state = {
        id: '',
        title: '',
        content: '',
        language: '',
    };

    private toastConfig = {
        autoClose: parseInt(process.env.REACT_APP_CLIENT_TOAST_AUTOCLOSE || '5000'),
        closeButton: ('true' === process.env.REACT_APP_CLIENT_TOAST_CLOSEBUTTON),
        draggable: ('true' === process.env.REACT_APP_CLIENT_TOAST_DRAGGABLE),
        limit: parseInt(process.env.REACT_APP_CLIENT_TOAST_LIMIT || '0'),
        newestOnTop: ('true' === process.env.REACT_APP_CLIENT_TOAST_NEWESTONTOP),
        position: (process.env.REACT_APP_CLIENT_TOAST_POSITION || 'top-right') as ToastPosition,
        pauseOnHover: ('true' === process.env.REACT_APP_CLIENT_TOAST_PAUSEONHOVER),
    }


    componentDidMount() {
        this.setState({
            id: this.props.content ? this.props.content['id'] : '',
            title: this.props.content ? this.props.content['title'] : '',
            content: this.props.content ? this.props.content['content'] : '',
            language: this.props.content ? this.props.content['language'] : '',
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                id: this.props.content ? this.props.content['id'] : '',
                title: this.props.content ? this.props.content['title'] : '',
                content: this.props.content ? this.props.content['content'] : '',
                language: this.props.content ? this.props.content['language'] : '',
            })
        }
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    private handleTitleChange(value) {
        this.setState({ 'title': value });
    }

    handleContentChange(value) {
        this.setState({ 'content': value.value });
    }

    handleLanguageChange(value) {
        this.setState({ 'language': value.value })
    }

    async submit() {
        try {
            await this.props.onSave(this.state);
        } catch (e) {

        }
    }

    render() {
        const languageOptions = commonUtils.generateLanguagesMap();

        return (
            <>
                <Modal.Header>{this.translate(`${this.props.edit ? 'Edit' : 'Add'} a translation`)}</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Grid>
                            <Grid.Row columns={this.props.edit ? 1 : 2}>
                                {!this.props.edit &&
                                    <Grid.Column>
                                        <Form widths={'equal'} className='product-definition-edit-content'>
                                            <Form.Input label={this.translate('Language')}>
                                                <div style={{ overflowY: 'auto', height: 'auto', width: '100%', padding: '.67857143em 1em', border: '1px solid rgba(34,36,38,.15)', borderRadius: '3px' }}>
                                                    {this.props.content ? commonUtils.renderLanguageRealName(this.props.content['language']) : ''}
                                                </div>
                                            </Form.Input>
                                        </Form>
                                    </Grid.Column>
                                }
                                <Grid.Column>
                                    <Form>
                                        <Form.Dropdown
                                            label={this.translate('Language')}
                                            required
                                            placeholder={this.translate('Select translation language')}
                                            selection
                                            options={languageOptions}
                                            labeled
                                            defaultValue={(this.props.edit && this.props.content) ? this.props.content['language'] : undefined}
                                            onChange={(e, data) => this.handleLanguageChange({ value: data.value })}
                                            width={'5'}
                                        />
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={this.props.edit ? 1 : 2}>
                                {!this.props.edit && <Grid.Column>
                                    <Form widths={'equal'} className='product-definition-edit-content'>
                                        <Form.Input label={this.translate('Title')}>
                                            <div style={{ overflowY: 'auto', height: 'auto', width: '100%', padding: '.67857143em 1em', border: '1px solid rgba(34,36,38,.15)', borderRadius: '3px' }}>
                                                <p>{this.props.content ? this.props.content['title'] : ''}
                                                    <span style={{ float: 'right' }}>
                                                        <Button icon='clipboard' size='tiny' onClick={() => this.copyIdToClipboard(this.props.content['title'])} />
                                                    </span>
                                                </p>
                                            </div>
                                        </Form.Input>
                                        <label>{this.translate('Content')}</label>
                                        <div style={{ overflowY: 'auto', height: '30vh', padding: '1rem', border: '1px solid rgba(34,36,38,.15)', borderRadius: '3px' }}>
                                            <ReactMarkdown children={this.props.content ? this.props.content['content'] : ''} />
                                        </div>
                                        <span style={{ float: 'right', padding: '0.65rem 0rem 0rem' }}>
                                            <Button icon='clipboard' size='tiny' onClick={() => this.copyIdToClipboard(this.props.content['content'])} />
                                        </span>
                                    </Form>
                                </Grid.Column>}
                                <Grid.Column>
                                    <Form onSubmit={() => this.submit()} widths={'equal'} className='product-definition-edit-content'>
                                        <Form.Input label={this.translate('Title')}>
                                            <input placeholder={this.translate('Title')} autoFocus defaultValue={this.props.edit && this.props.content ? this.props.content['title'] : ''} onChange={(e) => this.handleTitleChange(e.target.value)} />
                                        </Form.Input>
                                        <label>{this.translate('Content')}</label>
                                        <MarkdownEditor localizationService={this.props.localizationService} name='content' value={this.props.edit && this.props.content ? this.props.content['content'] : ''} onChange={(value) => this.handleContentChange(value)} />
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>

                    {!this.props.edit && <>
                        <Button color='black'
                            onClick={() => this.props.toggleModal(false)}
                        >
                            {this.translate('Cancel')}
                        </Button>
                        <Button
                            content={"Create Translation"}
                            labelPosition='right'
                            icon='checkmark'
                            onClick={() => { this.submit(); }}
                            positive
                        />
                    </>}
                    {this.props.edit && <>
                        <Button color='black'
                            onClick={() => this.props.toggleModal(false)}
                        >
                            {this.translate('Discard Changes')}
                        </Button> <Button
                            content={"Save Translation"}
                            labelPosition='right'
                            icon='save'
                            onClick={() => { this.submit(); }}
                            positive
                        />
                    </>}
                </Modal.Actions>
            </>
        )
    }

    async copyIdToClipboard(value) {
        try {
            await copy(value);
            toast.success("Copied content to clipboard.", this.toastConfig);
        } catch (e) {
            console.error(e);
            toast.error("There was an error. Please wait and try again.", this.toastConfig);
        }
    }
}
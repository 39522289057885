import React from 'react';
import ProductDefinitionsOverviewPage from '../../../components/pages/productdefinitions/ProductDefinitionsOverviewPage';
import GatekeeperFactory from '../../../factories/GatekeeperFactory';
import { Repository } from '../../../repositories';
import { LocalizationService } from '../../../services';
import LocalizedController from '../../LocalizedController';

export default class ProductDefinitionsOverviewController extends LocalizedController {
    private repository: Repository;
    private attributesRepository: Repository;
    private productsRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        productDefinition: undefined,
        productPropertyAttributes: [],
        productSelectionAttributes: [],
        products: [],
        customSku: false,
        productDefinitionContent: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, productDefinitionsRepository, attributesRepository, productsRepository, gatekeeperFactory] = props.args;

        this.repository = productDefinitionsRepository;
        this.attributesRepository = attributesRepository;
        this.productsRepository = productsRepository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    async componentDidMount() {
        await this.fetch();
    }

    private async fetch() {
        await this.fetchProductDefinition();
        await this.fetchProductAttributes();
        await this.fetchProducts();
        await this.getStorefrontSettings();
    }

    private async getStorefrontSettings() {
        let storefronts, storefront;

        try {
            storefronts = await this.productsRepository.getByPath('storefronts');
            if (storefronts && storefronts.length) {
                storefront = await this.productsRepository.getByPath(`storefronts/${storefronts[0].id}`);
                this.setState({
                    customSku: storefront.settings.products.productsHaveCustomSkus,
                })
            }
        } catch (e) {
            console.error('Issue with storefronts:', e);
        }
    }

    private async fetchProductDefinition() {
        let item;
        let productDefinitionContent = [];
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.getOne(this.params?.id);
            productDefinitionContent = await this.repository.getByPath(`definitions/${item.id}/contents`);
            productDefinitionContent = productDefinitionContent.sort((a, b) => a['language'] - b['language'])
        } catch (e) {
            console.error(e);
        }

        this.setState({ productDefinition: item, productDefinitionContent });
    }

    private async fetchProductAttributes() {
        let productAttributes;
        let productPropertyAttributes = [];
        let productSelectionAttributes = [];
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            productAttributes = await this.attributesRepository.find({ definitionId: this.params?.id });
            productPropertyAttributes = productAttributes.filter((item) => { return item.type == 'property' });
            productSelectionAttributes = productAttributes.filter((item) => { return item.type == 'selection' });
        } catch (e) {
            console.error(e);
        }

        this.setState({ productPropertyAttributes, productSelectionAttributes });
    }

    private async fetchProducts() {
        let products;
        const { productDefinitionContent } = this.state;
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            products = await this.productsRepository.find({ definitionId: this.params?.id });
            products.sort(function(a, b) {
                return parseFloat(a.price) - parseFloat(b.price);
            });
        } catch (e) {
            console.error(e);
        }
        this.setState({ products });
    }

    private async updateProducts(data: any[]) {
        for (let index = 0; index < data.length; index++) {
            try {
                await this.productsRepository.update(data[index]);
            } catch (e) {
                throw new Error(e);
            }
        }
    }

    private async handlePublishUnpublish(data) {
        let { productDefinition } = this.state;
        let item;
        if (!productDefinition) { return; }
        (productDefinition as any).status = data;
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.update(
                productDefinition
            );
        } catch (e) {
            console.error(e);
            return;
        }
        await this.fetch();
    }

    private async handleAttributePriceUpdate(data) {
        let newPrice = Number(data.newPrice);
        delete data.newPrice;
        let items;
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.productsRepository.find({ definitionId: this.params?.id });
        } catch (e) {
            console.error('error', e);
            return;
        }

        let subsetCompare = (obj1, obj2, keys) => keys.every(key => obj1[key] === obj2[key]);

        let toUpdate = items.map((item) => {
            if (subsetCompare(item.attributes, data, Object.keys(data)))
                return item;

        })

        await Promise.all(toUpdate.map(async (value) => {
            if (value && value.price) {
                value.price = newPrice;
                await this.productsRepository.update(value);
            }
        }))
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Store Management'), value: '/store-management' },
            { key: 2, text: this.localizationService.translate('Products'), value: '/store-management/products' },
            { key: 3, text: (this.state?.productDefinition as any)?.title, value: undefined },
        ]
    }

    render() {
        const { productDefinition,
            productPropertyAttributes,
            productSelectionAttributes,
            products,
            customSku,
        } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <ProductDefinitionsOverviewPage
                productDefinition={productDefinition}
                productPropertyAttributes={productPropertyAttributes}
                productSelectionAttributes={productSelectionAttributes}
                products={products}
                hasCustomSku={customSku}
                Gatekeeper={Gatekeeper}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
                updatePrices={(data) => { this.updateProducts(data) }}
                handlePublishUnpublish={async (data: string) => this.handlePublishUnpublish(data)}
                handleAttributePriceUpdate={async (data: string) => this.handleAttributePriceUpdate(data)}
            />
        )
    }
}
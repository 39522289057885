import React from 'react';
import PageEditPage from '../../components/pages/PageEditPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import { Repository } from '../../repositories';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';
import { slug } from 'slug-gen';

export default class PageCreateController extends LocalizedController {
    private repository: Repository;
    private websitesRepository: Repository;
    private layoutsRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        item: undefined,
        layoutMap: [],
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, websitesRepository, layoutsRepository, gatekeeperFactory] = props.args;

        this.repository = repository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
        this.websitesRepository = websitesRepository;
        this.layoutsRepository = layoutsRepository;
    }

    async componentDidMount() {
        let layoutMap: any[] = [];
        try {
            layoutMap = await this.getPageLayoutsMap();
            layoutMap = layoutMap.map((layout, index) => {
                return { key: index, text: layout['name'], value: layout['id'] }
            })
        } catch (e) {

        }
        this.setState({
            layoutMap
        })
    }

    private handleChange(key: string, value: any) {
        let item = this.state.item || {};

        item[key] = value;

        this.setState({
            item,
        });
    }

    private async handleSave(data: any) {
        let item;
        let layoutId;

        data['slug'] = slug(data.title);
        layoutId = data.layout;
        delete data.layout;

        try {
            item = await this.repository.create(data);
            await this.repository.linkByPath(`pages/${item.id}`, { layoutId });
            this.router.redirect(`/website-management/pages/${item.id}`);
        } catch (e) {
            throw e;
        }
    }

    private async getPageLayoutsMap() {
        let layout;
        let websiteInfo;
        let pageLayouts = [];

        try {
            websiteInfo = await this.websitesRepository.get();
            layout = await this.repository.getByPath(`websites/${websiteInfo[0].id}/templates`);
            pageLayouts = await this.repository.getByPath(`websites/${websiteInfo[0].id}/templates/${layout[0].id}/layouts`);
        } catch (e) {
            throw new Error(e);
        }
        return pageLayouts;
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 0, text: this.localizationService.translate('Website Management'), value: '/website-management' },
            { key: 1, text: this.localizationService.translate('Pages'), value: '/website-management/pages' },
            { key: 2, text: this.localizationService.translate('Create a new page'), value: undefined },
        ]
    }

    render() {
        const { item, layoutMap } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <PageEditPage
                item={item}
                onChange={(key: string, value: any) => this.handleChange(key, value)}
                onSave={async (data: any) => this.handleSave(data)}
                Gatekeeper={Gatekeeper}
                localizationService={this.localizationService}
                breadcrumbLevels={this.generateBreadcrumbs()}
                layoutMap={layoutMap}
            />
        )
    }
}
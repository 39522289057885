import React, { PureComponent } from 'react';
import { DesktopSkeleton } from '../../../layouts/skeleton';
import { Button, Container, Header, Table, Grid, Card, GridColumn, List, ListItem, Segment, GridRow, Label } from 'semantic-ui-react';
import { UnauthorizedMessage } from '../../../layouts/messages';
import { LoadingSpinner } from '../../../controls';
import CommonUtils from '../../../../utilities/Common/CommonUtils';
import './styles.css';

const commonUtils = new CommonUtils();

export interface IDesktopPageProps {
    createRoutes?: Function;
    items?: any;
    products?: any;
    panes?: any[];
    Gatekeeper: any;
    onDoubleClick: Function;
    localizationService: any;
    breadcrumbLevels: any;
    order?: any;
    invoice?: any;
    displaySku: boolean;
}
export interface IDesktopPageState {
    modalOpen: boolean;
}

export default class DesktopPage extends PureComponent<
    IDesktopPageProps,
    IDesktopPageState
> {
    state = {
        modalOpen: false,
    };

    static defaultProps = {
        onDoubleClick: () => { },
        products: [],
    }

    translate(key: string): string {
        return this.props.localizationService.translate(key);
    }

    handleDoubleClick(e, item) {
        const { onDoubleClick } = this.props;
        switch (e.detail) {
            case 2:
                onDoubleClick(item.id);
                break;
            default:
                return;
        }
    }

    private getItemTitle(item) {
        let lang = JSON.parse(localStorage.getItem('lang')!);
        if (!item.title) {
            let content = item.contents.filter((content) => content.language == lang)[0];
            return content.title;
        } return item.title;
    }

    private generateOrderLineItems() {
        const { invoice } = this.props;
        let subTotal = 0;
        let lang = JSON.parse(localStorage.getItem('lang')!);
        if (!invoice || !invoice.lineItems) { return; }

        let lineItemsTable = invoice.lineItems.map(item => {
            subTotal += item.item.price * item.quantity;
            if ("product" === item.item.type) {
                return (
                    <Table.Row>
                        <Table.Cell>
                            <span style={{ fontWeight: 'bolder' }}>{this.getItemTitle(item.item)}</span>
                            {this.props.displaySku && <span style={{ fontWeight: 'lighter' }}><br />SKU: {item.item.sku}</span>}
                        </Table.Cell>
                        <Table.Cell>
                            <span>{commonUtils.renderCurrency(item.item.price)}</span>
                        </Table.Cell>
                        <Table.Cell textAlign='right' verticalAlign='middle'>
                            {item.quantity}
                        </Table.Cell>
                        <Table.Cell textAlign='right' verticalAlign='middle'>
                            <span>{commonUtils.renderCurrency(Number(item.item.price) * Number(item.quantity))}</span>
                        </Table.Cell>
                    </Table.Row>
                )
            }
            return (
                <Table.Row>
                    <Table.Cell>
                        <span style={{ fontWeight: 'bolder' }}>{this.translate('Delivery fees')}</span>
                    </Table.Cell>
                    <Table.Cell>
                        <span>{commonUtils.renderCurrency(item.item.price)}</span>
                    </Table.Cell>
                    <Table.Cell textAlign='right' verticalAlign='middle'>
                    </Table.Cell>
                    <Table.Cell textAlign='right' verticalAlign='middle'>
                        <span>{commonUtils.renderCurrency(Number(item.item.price) * Number(item.quantity))}</span>
                    </Table.Cell>
                </Table.Row>
            )
        })

        lineItemsTable.push(
            <Table.Row>
                <Table.Cell colSpan="3">
                    <span style={{ fontWeight: 'bolder' }}>{this.translate('Subtotal')}</span><br />
                </Table.Cell>
                <Table.Cell textAlign='right' verticalAlign='middle'>
                    <span>{commonUtils.renderCurrency(Number(subTotal))}</span>
                </Table.Cell>
            </Table.Row>
        )

        if (invoice?.taxes && invoice?.taxes.length > 0) {
            lineItemsTable.push(
                <Table.Row>
                    <Table.Cell colSpan="3">
                        <span style={{ fontWeight: 'bolder' }}>TPS</span>
                        <br />
                        <span style={{ fontWeight: 'lighter' }}>{invoice.taxes.find((tax) => tax.tax.name == "TPS").tax.registrationNumber}</span>
                    </Table.Cell>
                    <Table.Cell verticalAlign='middle' textAlign="right">
                        {invoice?.taxes && invoice.taxes.length > 0 && commonUtils.renderCurrency(invoice.taxes.find((tax) => tax.tax.name == "TPS").value, `${lang}-CA`)}
                    </Table.Cell>
                </Table.Row>
            );

            lineItemsTable.push(
                <Table.Row>
                    <Table.Cell colSpan="3">
                        <span style={{ fontWeight: 'bolder' }}>TVQ</span>
                        <br />
                        <span style={{ fontWeight: 'lighter' }}>{invoice.taxes.find((tax) => tax.tax.name == "TVQ").tax.registrationNumber}</span>
                    </Table.Cell>
                    <Table.Cell verticalAlign='middle' textAlign="right">
                        {invoice?.taxes && invoice.taxes.length > 0 && commonUtils.renderCurrency(invoice.taxes.find((tax) => tax.tax.name == "TVQ").value, `${lang}-CA`)}
                    </Table.Cell>
                </Table.Row>
            );
        }


        lineItemsTable.push(
            <Table.Row>
                <Table.Cell colSpan="3">
                    <span style={{ fontWeight: 'bolder' }}>Total</span><br />
                </Table.Cell>
                <Table.Cell textAlign='right' verticalAlign='middle'>
                    <span>{commonUtils.renderCurrency(Number(invoice.total), `${lang}-CA`)}</span>
                </Table.Cell>
            </Table.Row>
        )
        return lineItemsTable;
    }

    private renderCustomerAddressCard() {
        return <Card fluid>
            <Card.Content>
                <Card.Header>Delivery Address</Card.Header>
                <List divided relaxed className='customer-order-information-card'>
                    <ListItem>
                        <List.Content floated='right'>
                            14 Angelo Rd.
                        </List.Content>
                        <List.Header>Address Line 1</List.Header>
                    </ListItem>
                    <ListItem>
                        <List.Content floated='right'>
                            P0S 7A1
                        </List.Content>
                        <List.Header>Postal Code</List.Header>
                    </ListItem>
                </List>
            </Card.Content>
        </Card>;
    }

    private renderCustomerDetailsCard() {
        return <Card fluid>
            <Card.Content>
                <Card.Header>Customer Details</Card.Header>
                <List divided relaxed className='customer-order-information-card'>
                    <ListItem>
                        <List.Content floated='right'>
                            Harun Laal
                        </List.Content>
                        <List.Header>Customer Name</List.Header>
                    </ListItem>
                    <ListItem>
                        <List.Content floated='right'>
                            514-555-5555
                        </List.Content>
                        <List.Header>Phone Number</List.Header>
                    </ListItem>
                    <ListItem>
                        <List.Content floated='right'>
                            Delivery
                        </List.Content>
                        <List.Header>Type</List.Header>
                    </ListItem>
                    <ListItem>
                        <List.Content floated='right'>
                            N/A
                        </List.Content>
                        <List.Header>Note</List.Header>
                    </ListItem>
                </List>
            </Card.Content>
        </Card>;
    }


    renderNoContentMessage() {
        return (
            <Container style={{ marginTop: '100px' }} textAlign='center' fluid>
                <Header>{this.translate('No products!')}</Header>
            </Container>
        )
    }

    render() {
        const { invoice } = this.props;
        const Gatekeeper = this.props.Gatekeeper;

        return (
            <DesktopSkeleton>
                <Gatekeeper name="product-management" unauthorizedComponent={<UnauthorizedMessage />} loadingComponent={<LoadingSpinner />}>
                    <Grid padded stackable>
                        <GridRow textAlign='justified'>
                            <GridColumn>
                                <Header content={`${this.translate("Invoice")} #${invoice.id}`} floated='left' />
                            </GridColumn>
                        </GridRow>
                        <GridColumn width={11} className="invoice-print-container">
                            <Segment className='invoice-print'>
                                <Table fluid={'true'}>
                                    <Table.Header>
                                        <Table.HeaderCell>{this.translate("Item")}</Table.HeaderCell>
                                        <Table.HeaderCell>{this.translate("Price")}</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'>{this.translate("Quantity")}</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'>Total</Table.HeaderCell>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.generateOrderLineItems()}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </GridColumn>
                        <GridColumn width={5}>
                            <Card fluid>
                                <Card.Content>
                                    <Card.Header>{this.translate("Invoice Details")}</Card.Header>
                                    <List divided relaxed className='customer-order-information-card'>
                                        <ListItem>
                                            <List.Content floated='right'>
                                                {commonUtils.renderDate(invoice.date)}
                                            </List.Content>
                                            <List.Header>{this.translate("Invoice Created Date")}</List.Header>
                                        </ListItem>
                                        <ListItem>
                                            <List.Content floated='right'>
                                                <Label color='grey' content={`${invoice.status}`} />
                                            </List.Content>
                                            <List.Header>{this.translate("Invoice Status")}</List.Header>
                                        </ListItem>
                                    </List>
                                </Card.Content>
                                <Card.Content extra>
                                    <div className='ui two buttons'>
                                        <Button content={this.translate('Print')} icon={'print'} onClick={() => { window.print() }} />
                                    </div>
                                </Card.Content>
                            </Card>
                        </GridColumn>
                    </Grid>
                </Gatekeeper>
            </DesktopSkeleton >
        );
    }
}

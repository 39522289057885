import React from 'react';
import PagesOverviewPage from '../../components/pages/PagesOverviewPage';
import GatekeeperFactory from '../../factories/GatekeeperFactory';
import Repository from '../../repositories/Repository';
import { LocalizationService } from '../../services';
import LocalizedController from '../LocalizedController';

export default class PageOverviewController extends LocalizedController {
    private repository: Repository;
    private sectionsRepository: Repository;
    private layoutsRepository: Repository;
    private gatekeeperFactory: GatekeeperFactory;
    private localizationService: LocalizationService;

    state = {
        item: undefined,
        sections: [],
        pageSections: undefined,
        locations: [],
        lastPageSectionPosition: 0,
        isFetching: true,
    };

    constructor(props) {
        super(props);

        const [router, routeMenuItemFactory, localizationService, repository, sectionsRepository, layoutsRepository, gatekeeperFactory] = props.args;
        this.repository = repository;
        this.sectionsRepository = sectionsRepository;
        this.layoutsRepository = layoutsRepository;
        this.gatekeeperFactory = gatekeeperFactory;
        this.localizationService = localizationService;
    }

    private async get() {
        let item;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.getOne(this.params?.id);
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            let layout = await this.repository.getByPath(`pages/${this.params?.id}/layouts`);
            item.layout = layout.length > 0 ? layout[0].name : undefined;
            item.layoutId = layout.length > 0 ? layout[0].id : undefined;
        } catch (e) {
            console.error(e);
            throw e;
        }

        return item;
    }

    private async getLocations(layoutId: string) {
        let items: any[] = [];

        try {
            items = await this.layoutsRepository.getByPath(`layouts/${layoutId}/locations`);
            items = items.map((location, index) => {
                return { key: index, text: location['name'], value: location['name'] }
            })
        } catch (e) {
            console.error(e);
            throw e;
        }
        return items;
    }

    private async getSections() {
        let items;

        try {
            items = await this.sectionsRepository.get();
        } catch (e) {
            console.error(e);
            throw e;
        }

        return items;
    }

    private async getPageSections() {
        let items;

        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            items = await this.repository.getByPath(`pages/${this.params?.id}/sections?sort=position:asc`);
        } catch (e) {
            console.error(e);
            throw e;
        }

        return items;
    }

    private async handleLinkSection(data) {
        try {
            const section = await this.sectionsRepository.getOne(data.sectionId);
            let additional = section.additional || {};
            if (additional.query)
                delete additional.query;
            let defaultBody = {
                "location": "main" === data.location ? '' : data.location,
                "position": this.state.lastPageSectionPosition,
                "style": {
                    "cssClassNames": []
                },
                "options": {
                    "layout":
                    {
                        "type": 'card',
                        "options": additional,
                    }
                }
            };
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.createByPath(defaultBody, `pages/${this.params?.id}/sections?sectionId=${section.id}`);
        } catch (e) {
            console.error(e)
            throw Error("Failed to associate the section to the page from this application. Please try again.");
        }
        try {
            await this.fetch();
        } catch (e) {
            console.error(e);
            // set default whenever there's an error.
        }
    }

    private async handleUnlinkSection(data) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.deleteByPath(`pages/${this.params?.id}/sections/${data}`)
        } catch (e) {
            console.error(e)
            throw Error("Failed to remove the section to the page from this application. Please try again.");
        }
        try {
            await this.fetch();
        } catch (e) {
            console.error(e);
            // set default whenever there's an error.
        }
    }

    private async handlePublishUnpublish(data) {
        let { item } = this.state;
        if (!item) { return; }
        (item as any).status = data;
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            item = await this.repository.update(
                item
            );
        } catch (e) {
            console.error(e);
            throw e;
        }
        try {
            await this.fetch();
        } catch (e) {
            console.error(e);
            // set default whenever there's an error.
        }
    }

    private async swapPositions(data) {
        let firstItem;
        let secondItem;
        let temporaryPosition;
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            firstItem = await this.repository.getOneByPath(`pages/${this.params?.id}/sections/${data.firstId}`);
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            secondItem = await this.repository.getOneByPath(`pages/${this.params?.id}/sections/${data.secondId}`);
            temporaryPosition = secondItem.position;
            secondItem.position = firstItem.position;
            firstItem.position = temporaryPosition;
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.updateByPath(firstItem, `pages/${this.params?.id}/sections/${data.firstId}?sectionId=${firstItem.sectionId}`);
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.updateByPath(secondItem, `pages/${this.params?.id}/sections/${data.secondId}?sectionId=${secondItem.sectionId}`);
        } catch (e) {
            console.error(e);
            throw Error("Failed to change positions.");
        }
        try {
            await this.fetch();
        } catch (e) {
            console.error(e);
            // set default whenever there's an error.
        }
    }

    async componentDidMount() {
        try {
            await this.fetch();
        } catch (e) {
            console.error(e);
            // set default whenever there's an error.
        }
    }

    private async fetch() {
        let item, locations, sections, pageSections, splitPageSections, lastPageSectionPosition;
        try {
            item = await this.get();
        } catch (e) {
            console.error('Failed to get Page')
            throw new Error(e);
        }
        if (!item.layoutId) {
            throw new Error('No layout ID associated to this page.');
        }
        try {
            locations = await this.getLocations(item.layoutId);
        } catch (e) {
            console.error('Failed to get Page Locations')
            throw new Error(e);
        }
        try {
            sections = await this.getSections();
        } catch (e) {
            console.error('Failed to get Sections')
            throw new Error(e);
        }
        try {
            pageSections = await this.getPageSections();

            sections = sections && sections.filter(section => section.language === item.language);
            lastPageSectionPosition = pageSections && pageSections.length > 0 ? pageSections[pageSections.length - 1].position + 1 : 0;
            splitPageSections = (locations || []).reduce((accumulator, current) => {
                if (!current) {
                    return accumulator;
                }
                if (current.value == "main") {
                    accumulator[current.value] = pageSections.filter(section => { return !section.location });
                    return accumulator;
                }
                accumulator[current.value] = pageSections.filter(section => { return section.location && section.location === current.value });
                return accumulator;
            }, {});
        } catch (e) {
            console.error('Failed to get Page Sections')
            throw new Error(e);
        }

        delete item.layoutId;
        this.setState({
            item,
            sections,
            pageSections: splitPageSections,
            lastPageSectionPosition,
            locations,
            isFetching: false,
        });
    }

    private async handleUpdatePageSection(pageSection: any) {
        try {
            //@ts-ignore - params is declared on the vendor Controller: https://gitlab.com/tramwayjs/tramway-router-react-strategy/-/blob/master/dev/core/controllers/ReactController.js#L13
            await this.repository.updateByPath(pageSection, `pages/${this.params?.id}/sections/${pageSection.id}?sectionId=${pageSection.sectionId}`);
            await this.fetch();
        } catch (e) {
            console.error(e);
        }
    }

    generateBreadcrumbs() {
        return [
            { key: 0, text: this.localizationService.translate('Overview'), value: '/' },
            { key: 1, text: this.localizationService.translate('Website Management'), value: '/website-management' },
            { key: 2, text: this.localizationService.translate('Pages'), value: '/website-management/pages' },
            { key: 3, text: this.state.item ? this.state.item!['title'] : '', value: undefined },
        ]
    }

    render() {
        const { item, sections, pageSections, locations, isFetching } = this.state;

        const Gatekeeper = this.gatekeeperFactory.create();

        return this.prepare(
            <PagesOverviewPage
                item={item}
                sections={sections}
                pageSections={pageSections}
                Gatekeeper={Gatekeeper}
                breadcrumbLevels={this.generateBreadcrumbs()}
                handleLinkSection={async (data: string) => this.handleLinkSection(data)}
                handleUnlinkSection={async (data: string) => this.handleUnlinkSection(data)}
                swapPositions={async (data: string) => this.swapPositions(data)}
                handlePublishUnpublish={async (data: string) => this.handlePublishUnpublish(data)}
                locations={locations}
                handleUpdatePageSection={async (data: any) => this.handleUpdatePageSection(data)}
                isFetching={isFetching}
            />
        )
    }
}
import React, { PureComponent } from "react";
import { Button, Checkbox, Divider, Dropdown, Label, LabelGroup, List, Modal } from 'semantic-ui-react'
import CommonUtils from "../../../../utilities/Common/CommonUtils";
import "react-toggle/style.css"
import QRCode from "qrcode.react";

const commonUtils = new CommonUtils();

export interface IQRCodeModalProps {
    value: string;
}

export interface IQRCodeModalState {
    size: number;
}

export default class QRCodeModal extends PureComponent<IQRCodeModalProps, IQRCodeModalState> {

    static defaultProps = {
        value: '',
    }

    state = {
        size: 256,
    }

    componentDidMount() {
    }

    downloadQR() {
        const canvas = document.getElementById("domain-qr") as HTMLCanvasElement;
        if (canvas) {
            let pngUrl = canvas.toDataURL("image/png");
            pngUrl.replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = `${this.props.value}-qr-${this.state.size}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    }

    render() {


        return (
            <>
                <Modal.Header>QR Code</Modal.Header>
                <Modal.Content>
                    <Modal.Description style={{ textAlign: 'center' }}>
                        <QRCode
                            value={this.props.value}
                            size={256}
                            level={'H'}
                        />
                        <QRCode
                            style={{ display: 'none' }}
                            value={this.props.value}
                            size={this.state.size}
                            level={'H'}
                            id='domain-qr'
                        />
                        <Divider />
                        <div style={{ margin: '5% 0px' }}>Change size: <Dropdown
                            value={this.state.size}
                            options={[
                                { key: 0, text: '64px', value: 64 },
                                { key: 0, text: '128px', value: 128 },
                                { key: 0, text: '256px', value: 256 },
                                { key: 0, text: '512px', value: 512 },
                                { key: 0, text: '1024px', value: 1024 },
                            ]}
                            onChange={(event, { value }) => this.setState({ size: Number(value) })}
                        /></div>
                        <Button fluid icon='download' color='green' onClick={() => { this.downloadQR() }} content='Download QR Code'></Button>
                    </Modal.Description>
                </Modal.Content>
            </>
        );
    }
}